import helper from './helper';

if (helper.elExists(['#jsHeader'])) {
  import( /* webpackChunkName: "header" */ './components/header');
}

if (helper.elExists(['#jsNav'])) {
  import( /* webpackChunkName: "nav" */ './components/nav');
}

if (helper.elExists(['.jsLightboxVideoCall', '.jsLightboxCall', '#jsLightboxPromotion'])) {
  import( /* webpackChunkName: "lightbox" */ './components/lightbox');
}

if (helper.elExists(['.splide'])) {
  import( /* webpackChunkName: "carousel" */ './components/carousel');
}

if (helper.elExists(['#jsToTop'])) {
  import( /* webpackChunkName: "totop" */ './components/totop');
}

if (helper.elExists(['#jsSticky'])) {
  import( /* webpackChunkName: "sticky" */ './components/sticky');
}

if (helper.elExists(['.jsRating'])) {
  import( /* webpackChunkName: "rating" */ './components/rating');
}