class Tab {
  constructor(className) {
    this.className = className;
    this.btnEl = document.getElementById("jsSearchBtn");
    this.selectEl = null;
    this.init();
  }

  init() {
    this.tabTtlEls = document.querySelectorAll(this.className);
    this.selectEl = document.getElementById("jsSelectLocation");
    if (this.tabTtlEls.length > 0) {
      if (this.selectEl) {
        this.selectEl.dataset.type = this.tabTtlEls[0].value;
      }
      this.tabTtlEls.forEach((tabTtlEl) => {
        this.change(tabTtlEl, this.selectEl);
      });
    }
  }
  change(el, selectEl) {
    el.addEventListener("change", (e) => {
      if (selectEl) {
        selectEl.dataset.type = el.value;
        selectEl.value = "";
        this.btnEl.dataset.state = "disable";
      }
    });
  }
}
class BtnSumit {
  constructor(className) {
    this.className = className;
    this.init();
  }

  init() {
    this.btnSubmitEls = document.querySelectorAll(this.className);
    if (this.btnSubmitEls.length > 0) {
      this.btnSubmitEls.forEach((btnSubmitEl) => {
        this.click(btnSubmitEl);
      });
    }
  }

  click(el) {
    el.addEventListener("click", (e) => {
      el.dataset.state = "submit";
      setTimeout(() => {
        el.dataset.state = "";
      }, 10000);
    });
  }
}
class Anchor {
  constructor() {
    this.anchorEls = document.querySelectorAll(".jsAnchor");
    this.top = 100;
    this.init();
  }

  init() {
    if (this.anchorEls.length > 0) {
      this.anchorEls.forEach((anchorEl) => {
        anchorEl.addEventListener(
          "click",
          this.anchorClick.bind(this, anchorEl)
        );
      });
    }
    this.hash = window.location.hash;
    if (this.hash) {
      let anchorTargetEl = document.getElementById(this.hash.replace("#", ""));
      this.move(anchorTargetEl, "init");
    }
  }

  move(el, state) {
    if (state === "init") {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
    }
    $("html, body").animate(
      {
        scrollTop: el.offsetTop - this.top,
      },
      500
    );
  }

  anchorClick(el, e) {
    let anchorTargetEl = document.getElementById(el.dataset.anchor);
    if (anchorTargetEl) {
      this.move(anchorTargetEl, "click");
      e.preventDefault();
    }
  }
}

class RandomBg {
  constructor(className) {
    this.className = className;
    this.init();
  }

  init() {
    this.randomBgEls = document.querySelectorAll(this.className);
    if (this.randomBgEls.length > 0) {
      this.randomBgEls.forEach((randomBgEl) => {
        randomBgEl.style.backgroundColor = this.color();
      });
    }
  }

  color() {
    const hex = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += hex[Math.floor(Math.random() * 16)];
    }
    return color;
  }
}

window.addEventListener("load", (e) => {
  new Tab(".jsTabTtl");
  new BtnSumit(".jsBtnSubmit");
  new Anchor();
  new RandomBg(".jsRandomBg");
});
